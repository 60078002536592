//Scripts
import React from 'react';
import i18next from 'i18next';
import GenericPopUp from './genericPopUp.js'

export default function LanguageMenu({ activePopUp, setActivePopUp, changeLanguage }) {
    if (activePopUp !== "language")
        return null;
    return (
        <GenericPopUp
            items={Object.keys(i18next.options.resources)
                .sort()
                .map(value => ({
                    value,
                    label: "language-" + value
                }))}
            onSelect={(languageCode) => changeLanguage(languageCode)}
            onClose={item => setActivePopUp(null)} />
    );
}
