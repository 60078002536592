//Scripts
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import signUp from "./assets/signUp.svg";
import { IsProd } from '../utils/environment';
import { sha256 } from '../utils/businessUtils';
import DistinctButton from "../basics/distinctButton";
import ProcessingCircle from './../utils/processingCircle.js';

//CSS
import "./emailRegistration.css";

function EmailRegistration({ setLoginUser }) {
//   const bookMatch = useMatch("/book");
  const { t, i18n } = useTranslation();
  const language_code = i18n.language;
  const [email, setEmail] = useState("");
  const [statusLabel, setStatusLabel] = useState(null);
    const [processing, setProcessing] = useState(false);

  const validateEmail = (e) => {
    const input = e.target;
    if (input.validity.typeMismatch) {
      input.setCustomValidity(t("login-invalid-email"));
    } else {
      input.setCustomValidity("");
    }
  };

  const initUser = async (email) => {
      setProcessing(true);
      try {
        const response = await fetch('/api/account/init.php',
        {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'User-Agent': navigator.userAgent,
          },
          body: JSON.stringify({
              email,
              language_code,
              send_email: true,
              return_url: window.location.href
          })
        });
        setProcessing(false);
        
        if (response.status !== 200)
        {
          setStatusLabel(t("login-no-server-connection"));
          return;
        }

        const data = await response.json();
        if (data.success) {
          setStatusLabel(data.statusLabel);
          const emailHash = await sha256(email.toLowerCase().trim());
          if (data.statusLabel === 'login-new-account' && IsProd()) {
              // Trigger Google Ads Conversion Tracking
              window.gtag('event', 'conversion', {
                'send_to': 'AW-16578861916/yJqWCOvfwccZENy2teE9',
                'value': 2.0, // Optional: Set a value for the conversion
                'currency': 'CHF', // Adjust currency if needed
                'user_data': {
                  'email_address': emailHash // Gehashte E-Mail-Adresse
                },
                'event_callback': 'callback'
                // 'transaction_id': data.userId // Use a unique ID if available
              });
          }
        }
      } catch (error) {
        console.error('Error:', error);
      }
  }

  const emailSubmit = (e) => {
    e.preventDefault();
    initUser(email);
  };

  return (
    <div className="login_container">
      <h1>{t('login-register-header')}</h1>
      <form onSubmit={emailSubmit}>
        <table>
          <tbody>
            <tr>
              <td>{t('login-register-reset-info')}</td>
            </tr>
            <tr>
              <td>
                <label>{t("login-email")}:</label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onInvalid={validateEmail}
                  placeholder="email@beispiel.com"
                  required
                />
                {statusLabel ? <div className="error-message">{t(statusLabel)}</div> : ""}
              </td>
            </tr>
            <tr>
              <td className='button-or-processing'>
                {processing ?
                  <ProcessingCircle></ProcessingCircle> :
                  <DistinctButton type="submit" id="submit">
                    {t("submit")}
                  </DistinctButton>
                }
              </td>
            </tr>
          </tbody>
        </table>
      </form>
      <img src={signUp} alt="signIn" id="register" />
      </div>
  );
}

export default EmailRegistration;
