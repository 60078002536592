export default function TramSvg({ className, width, height }) {
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg"
            className={className} 
            width={width} 
            height={height} 
            viewBox="0 0 90 90"
            strokeWidth=""
            stroke="currentColor">
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M39.189 75.21h-15.813c-1.245 0-1.595.539-1.375 1.725.471.949 2.239 6.21 9.281 6.21 7.043 0 8.81-5.261 9.282-6.21.22-1.186-.13-1.725-1.375-1.725m24.063 0h-15.813c-1.245 0-1.595.539-1.375 1.725.472.949 2.239 6.21 9.282 6.21 7.042 0 8.81-5.261 9.281-6.21.22-1.186-.13-1.725-1.375-1.725M81.472 54.51h-9.97c-.761 0-1.375-.621-1.375-1.38v-27.945c0-.8.574-1.38 1.375-1.38h1.032c.604 0 1.138.398 1.375 1.035l8.594 27.945c.183.94-.39 1.725-1.031 1.725zm-16.157-1.725c0 .911-.822 1.725-1.719 1.725h-8.938c-.898 0-1.719-.812-1.719-1.725v-27.255c0-.912.822-1.725 1.719-1.725h8.938c.898 0 1.719.812 1.719 1.725v27.255zm-17.188-6.555c0 .497-.534 1.035-1.032 1.035h-22c-.499 0-1.032-.537-1.032-1.035v-21.39c0-.497.535-1.035 1.032-1.035h22c.499 0 1.032.536 1.032 1.035v21.39zm30.594-22.77c-.665-2.424-2.997-4.14-5.5-4.14h-14.019l-16.232-18.285c-.832-1.243-2.499-1.34-3.437-.345-1.237.9719-1.332 2.6631-.344 3.795l13.169 14.835h-52.358l7.1054e-15 4.485h17.876c.724-.119 1.26.418 1.375 1.035v21.045c-.115.745-.652 1.283-1.375 1.38h-17.876l7.1054e-15 22.77h86.972c.493 0 1.031-.539 1.031-1.035v-16.215l-9.282-29.325z" />
        </svg>
   );
}
