import { useTranslation } from "react-i18next";
import Contacts from "./contacts.js"
import Location from "./location.js"

//CSS
import "./contactsAndLocation.css";

export default function ContactsAndLocation() {
    const { t } = useTranslation();
    return (
        <div className="contacts-location">
            <div className="contacts-container">
                <h1>{t('header-contact-us')}</h1>
                <Contacts></Contacts>
            </div>
            <Location></Location>
        </div>
    );
}
