import React from "react";
import classNames from "classnames"; // Importing the classnames utility
import "./distinctButton.css";

const DistinctButton = ({ children, onClick, className, ...props }) => {
    const buttonClass = classNames("distinct-button", className); // Combine the default and passed className

    return (
        <button className={buttonClass} onClick={onClick} {...props}>
            <span className="distinct-button-span">
                {React.isValidElement(children) ? children : <span>{children}</span>}
            </span>
        </button>
    );
};

export default DistinctButton;
