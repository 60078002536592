import { useEffect, useState } from "react";
import "./anchor.css";
import DistinctButton from "../basics/distinctButton";

export default function Anchor() {
  const [backToTopButton, setBackToTopButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setBackToTopButton(true);
      } else {
        setBackToTopButton(false);
      }
    });
  }, []);

  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  
  return (
    <div>
      {backToTopButton && (
        <DistinctButton className="back-to-top-button" onClick={scrollUp}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
            <line x1="5" y1="32" x2="25" y2="17" strokeWidth="4"/>
            <line x1="45" y1="32" x2="25" y2="17" strokeWidth="4"/>
          </svg>
        </DistinctButton>
      )}
    </div>
  );
}
