import React from "react";
import { useTranslation } from 'react-i18next';
import LangLink from './../translations/LangLink.js'
import "./genericPopUp.css";

function GenericPopUp({ items, onSelect, onClose }) {
    const { t } = useTranslation();
    return (
        <div className="menu_container" onClick={onClose}>
            <div className="popup_menu">
                {items.map((item, index) => (
                    item.href ? (
                        <LangLink key={index} to={item.href} className="menu_item">
                            {t(item.label)}
                        </LangLink>
                    ) : (
                        <button key={index} onClick={() => onSelect(item.value)} className="menu_item">
                            {t(item.label)}
                        </button>
                    )
                ))}
            </div>
        </div>
    );
}

export default GenericPopUp;
