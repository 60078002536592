//Scripts
import React, { useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { stringFormat } from "./../calendar/calendarLogic";
import DistinctButton from "../basics/distinctButton";
import signIn from "./assets/signIn.svg";

// //CSS
import "./login.css";

function Login({ setLoginUserCacheAndCookies }) {
  const [email, setEmail] = useState(null);
  const { t } = useTranslation();
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState({ count: 0, message: null });
  // const navigate = useNavigate();

  const loginUser = async (email, pw) => {
    try {
      const response = await fetch("/api/account/login.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "User-Agent": navigator.userAgent,
        },
        body: JSON.stringify({ email, pw }),
      });

      if (response.status !== 200) {
        setLoginError({ count: 0, message: t("login-no-server-connection") });
        return;
      }

      const data = await response.json();
      if (data.success) {
        setLoginUserCacheAndCookies(data.user);
      } else {
        const newLoginError = {
          count: loginError.count + 1,
          message: stringFormat(t("login-invalid"), loginError.count + 1),
        };
        setLoginError(newLoginError);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const validateEmail = (e) => {
    const input = e.target;
    if (input.validity.typeMismatch) {
      input.setCustomValidity(t("login-invalid-email"));
    } else {
      input.setCustomValidity("");
    }
  };

  const emailPasswordSubmit = (e) => {
    e.preventDefault();
    loginUser(email, password);
  };

  return (
    <div className="login_container">
      <h1>{<Trans i18nKey='home-welcome' />}</h1>
      <div className="login_section">
        <form onSubmit={emailPasswordSubmit}>
          <table>
            <tbody>
              <tr>
                <td>
                  <label>{t("login-email")}:</label>
                  <input
                    type="email"
                    value={email || ""}
                    onChange={(e) => setEmail(e.target.value)}
                    onInvalid={validateEmail}
                    placeholder="email@beispiel.com"
                    required
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <label>{t("login-password")}:</label>
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    required
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <DistinctButton type="submit" id="submit">
                    {t("submit")}
                  </DistinctButton>
                  {loginError.message ? <p className="error-message">{loginError.message}</p> : null}
                </td>
              </tr>
            </tbody>
          </table>
        </form>
        <img src={signIn} alt="signIn" />
      </div>
    </div>
  );
}

export default Login;
