export default function WhatsappSvg() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            fill="none"
        >
            <path 
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M 12.0389,23.0273 C 9.94962,23.0411 7.90032,22.4534 6.1303,21.343 L 5.70234,21.067 1.33979,22.1714 2.49946,17.9055 2.22334,17.4637 C 1.3471,15.7736 0.923785,13.8853 0.994648,11.9829 0.994648,2.14267 12.891,-2.78356 19.8483,4.17368 26.8055,11.1309 21.8792,23.0273 12.0389,23.0273 Z m 6.1022,-7.7586 c -0.3313,-0.1658 -1.9604,-0.9665 -2.2642,-1.0769 -0.3036,-0.1097 -0.5245,-0.1657 -0.7454,0.1657 -0.221,0.3312 -0.856,1.0769 -1.0492,1.2976 -0.1933,0.221 -0.3867,0.2485 -0.7179,0.082 C 13.0331,15.5718 11.9562,15.213 10.6861,14.0809 9.95965,13.4126 9.33628,12.6403 8.8362,11.7891 8.64291,11.4578 8.8362,11.2645 8.97425,11.0712 9.11229,10.9331 9.30559,10.6846 9.47127,10.4914 9.63692,10.298 9.69219,10.1601 9.80258,9.93918 9.91239,9.71827 9.85748,9.52504 9.77514,9.35938 9.69219,9.19419 9.02947,7.56511 8.75336,6.90245 8.47724,6.23978 8.20114,6.35022 8.00786,6.35022 H 7.3728 c -0.22088,0 -0.57982,0.0828 -0.88355,0.41418 -0.30372,0.33132 -1.15967,1.13204 -1.15967,2.76111 0,1.62909 1.18728,3.17519 1.35295,3.39609 0.16567,0.221 2.31933,3.5343 5.63257,4.9425 1.988,0.8559 2.7611,0.9387 3.7552,0.8007 0.5798,-0.082 1.7946,-0.7179 2.0431,-1.4083 0.2485,-0.6901 0.2559,-1.2667 0.2503,-1.4275 0,-0.138 0.1086,-0.3946 -0.2226,-0.5603 z" 
            />
        </svg>
    );
}
