export default function CarSvg({ className, color = "currentColor", width, height }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 13000 13000"
            className={className}
            width={width}
            height={height}
            fill={color}>
            <g transform="translate(0,12800) scale(1,-1)">
                <path d="m 3299,10336 c -2,-2 -47,-11 -99,-20 -365,-62 -671,-286 -833,-612 -19,-38 -296,-690 -615,-1449 -319,-759 -585,-1392 -592,-1406 -9,-20 -28,-31 -83,-48 C 992,6773 817,6693 745,6648 384,6420 134,6065 46,5655 0,5442 0,5457 0,3695 V 2030 l 513,-2 512,-3 6,-580 5,-580 27,-100 c 29,-107 85,-233 142,-319 106,-160 285,-302 468,-371 76,-28 298,-75 357,-75 66,0 285,48 367,81 298,118 514,364 600,684 l 27,100 5,580 6,580 h 3365 3365 l 6,-590 c 5,-514 8,-598 23,-655 54,-205 145,-361 292,-500 156,-146 323,-225 563,-265 114,-19 154,-17 316,16 391,79 695,380 781,774 15,71 17,147 18,650 l 1,570 510,5 510,5 5,1670 c 5,1525 4,1681 -12,1795 -28,212 -60,323 -139,490 -81,169 -166,289 -308,430 -184,183 -369,299 -599,375 -65,21 -85,32 -96,53 -8,15 -278,657 -601,1427 -323,770 -603,1429 -622,1465 -113,205 -311,391 -519,486 -99,45 -186,71 -320,94 -111,20 -163,20 -3192,20 -1693,0 -3081,-2 -3083,-4 z m 6162,-837 c 68,-32 127,-89 156,-149 49,-105 1023,-2442 1023,-2455 0,-13 -532,-15 -4241,-15 H 2158 l 4,28 c 5,27 980,2367 1017,2440 42,83 109,138 204,168 34,10 578,12 3032,11 l 2990,-2 z M 2185,5879 c 252,-25 465,-126 640,-303 195,-197 295,-441 295,-719 -1,-469 -314,-871 -775,-997 -120,-33 -329,-38 -455,-12 -200,41 -366,129 -513,271 -154,148 -254,330 -296,538 -120,589 300,1161 899,1222 47,4 88,9 91,10 3,0 55,-4 114,-10 z m 8629,-10 c 224,-32 477,-180 626,-365 286,-358 307,-833 54,-1221 -52,-79 -204,-230 -290,-286 -85,-56 -206,-109 -317,-139 -71,-20 -106,-23 -252,-22 -141,0 -183,4 -247,22 -183,50 -348,145 -475,273 -129,129 -213,271 -269,455 -26,83 -28,102 -28,269 0,154 3,191 22,258 51,185 138,334 271,468 243,242 546,339 905,288 z" />
            </g>
        </svg>
    );
}
