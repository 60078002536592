const GoogleMaps = () => {
    return (
        <iframe 
            title='google-map'
            className="google-map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d21592.281072551476!2d8.539075687825807!3d47.430758262805014!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xed0b36801952401%3A0xbfe121447bb96905!2sAnatomicus%20Medizinische%20Massage%20GmbH!5e0!3m2!1sen!2sch!4v1727713591618!5m2!1sen!2sch" 
            style={ {"border": 0}} 
            allowFullScreen="" 
            loading="lazy" 
            referrerPolicy="no-referrer-when-downgrade">
        </iframe>
    )
};

export default GoogleMaps;
