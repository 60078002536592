export default function BusSvg({ className, width, height }) {
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg"
            className={className} 
            width={width} 
            height={height} 
            viewBox="0 0 256 256"
            strokeWidth=""
            stroke="currentColor">
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M214 33h-214v13h46c2.884-.154 5.457 2.415 5 5v63c.457 2.5-2.116 5.069-5 5h-46v81h39c10 0 9.884-12.846 10-13-.116-24.254 20.467-44.808 45-45 24.352.192 44.935 20.746 45 45-.065 13 10.226 13 10 13h101c3.427 0 6-2.569 6-6v-62l-24-85c-3.015-7.577-10.734-14-18-14zm-79 81c0 2.439-2.566 5-5 5h-58c-2.434 0-5-2.561-5-5v-63c0-2.439 2.566-5 5-5h58c2.434 0 5 2.561 5 5v63zm53 21c0 2.425-2.581 5-5 5h-30c-2.419 0-5-2.575-5-5v-84c0-2.425 2.581-5 5-5h30c2.419 0 5 2.575 5 5v84zm18 5c-2.431 0-5-2.575-5-5v-83c0-4.137 1.853-6 6-6h7c2.411 0 4.98 1.288 5 4l26 82c.95 5.425-1.618 8-5 8h-34zm-112 11c-20.571 0-36 16.714-36 36 0 20.571 16.714 36 36 36 20.571 0 36-16.714 36-36s-16.714-36-36-36zm.513 56c-11.179 0-20.513-9.333-20.513-19.487 0-11.179 9.333-20.513 20.513-20.513 10.154 0 19.487 9.333 19.487 20.513 0 10.154-9.333 19.487-19.487 19.487z" />
        </svg>
   );
}
