//Scripts
import React from 'react';
import GenericPopUp from './genericPopUp.js'

export default function TherapistPopUp({ activePopUp, setActivePopUp, setPopup, logout }) {
    if (activePopUp !== "therapist")
        return null;

    const items = [
        { href: "/cashRegister", label: "cash-register" },
        { href: "/operationPlan", label: "operations-plan" },
        { href: "/absences", label: "absence-button" },
        { href: "/clientList", label: "clientList-button" },
        { href: "/bookingList", label: "bookingList-button" },
        { href: "/voucherManagement", label: "menu-vouchers" },
        { label: "profile-logout" }
    ];
    return (
        <GenericPopUp
            items={items}
            onSelect={() => { setPopup(null); logout(); }}
            onClose={item => setActivePopUp(null)} />
    );
}
