import React from "react";
import { useTranslation } from "react-i18next";

// CSS
import "./refund.css";

export default function Refund() {
    const { t } = useTranslation();
    
    return (
        <div className="refund-container">
            <h1>{t('therapists-refund-header')}</h1>
            <p>{t('therapists-refund-text')}</p>
        </div>
    )
}
