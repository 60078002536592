import React, { createContext, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';

// Create the context for the calendar selection
const CalendarSelectionContext = createContext();

// Custom hook to use the calendar selection context
export const useCalendarSelection = () => useContext(CalendarSelectionContext);

// The StateProvider that will wrap the components that need access to the state
export const CalendarSelectionProvider = ({ children }) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const initialArrangementId = parseInt(searchParams.get('arrangementid')) || 2; // Default selection
    const initialTherapyId = parseInt(searchParams.get('therapyid')) || 1; // Default selection

    const [therapyId, setTherapyId] = useState(initialTherapyId);
    const [arrangementId, setInitialArrangementId] = useState(initialArrangementId);
    const [selectedDays, setSelectedDays] = useState([]);
    const [selectedWeekDays, setSelectedWeekDays] = useState([]);
    const [selectedCalendarWeeks, setSelectedCalendarWeeks] = useState([]);
    const [timeFilter, setTimeFilter] = useState([]);

    // Define the value to be provided to the context
    const value = {
        therapyId, setTherapyId,
        arrangementId, setInitialArrangementId,
        selectedDays, setSelectedDays,
        selectedWeekDays, setSelectedWeekDays,
        selectedCalendarWeeks, setSelectedCalendarWeeks,
        timeFilter, setTimeFilter
    };

    return (
        <CalendarSelectionContext.Provider value={value}>
            {children}
        </CalendarSelectionContext.Provider>
    );
};
