//Scripts
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import GenericPopUp from './genericPopUp.js'
import Login from "./../login/login.js";
import EmailRegistration from "./../login/emailRegistration.js";

export default function LoginMenu({ activePopUp, setActivePopUp, setPopup, logout, loginUser, setLoginUserCacheAndCookies }) {
    const { t } = useTranslation();
      const [activeTab, setActiveTab] = useState("login");
    if (activePopUp !== "login")
        return null;
    if (!loginUser) {
        return (
          <div className="account-overview menu_container" onClick={item => setActivePopUp(null)}>
            <div className="account_card" onClick={(e) => e.stopPropagation()}>
              <div className="tab-buttons">
                <button
                  onClick={() => setActiveTab("login")}
                  className={activeTab === "login" ? "active" : ""}>
                  <span>{t("login")}</span>
                </button>
                <button
                  onClick={() => setActiveTab("register")}
                  className={activeTab === "register" ? "active" : ""}>
                  <span>{t("login-no-password")}</span>
                </button>
              </div>
              <div className="tab-content">
                {activeTab === "login" && (
                  <div className="login_tab">
                    <Login setLoginUserCacheAndCookies={setLoginUserCacheAndCookies} />
                  </div>
                )}
                {activeTab === "register" && (
                  <div className="registration_tab">
                    <EmailRegistration />
                  </div>
                )}
              </div>
            </div>
          </div>
        );
    }

    const items = [
        { href: "/account", label: "profile-data" },
        { href: "/bookings", label: "profile-bookings" },
        { label: "profile-logout" },
    ];
    return (
        <GenericPopUp
            items={items}
            onSelect={() => { setPopup(null); logout(); }}
            onClose={item => setActivePopUp(null)} />
    );
}
