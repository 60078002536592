// CSS
import './App.css';

// Scripts
import React, { useState, useEffect, Suspense, lazy } from 'react';
import { Route, Routes, useLocation, useMatch, useNavigate } from 'react-router-dom';
import { useTranslation, initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import Cookies from 'js-cookie';
import axios from 'axios';
import { IsProd } from './utils/environment.js';
import { getBrowserLanguage } from './utils/businessUtils.js';
import Anchor from "./anchor/anchor.js";
import Refund from "./refund/refund.js";
import { CalendarSelectionProvider } from './bookingOptions/calendarSelectionContext.js';
import ContactsAndLocation from './contacsAndLocation/contactsAndLocation.js'
import ScrollToTop from './basics/scrollToTop.js'

// Pop ups
import LanguageMenu from "./popUps/languageMenu.js";
import LoginMenu from "./popUps/loginMenu.js"
import TherapistPopUp from "./popUps/therapistPopUp.js"

// Translations
import enTranslation from './translations/en.json';
import deTranslation from './translations/de.json';
import ruTranslation from './translations/ru.json';

// Lazy load components
const TherapyAndDuration = lazy(() => import('./therapy/therapy-duration'));
const Calendar = lazy(() => import('./calendar/calendar.js'));
const Account = lazy(() => import('./login/account.js'));
const Header = lazy(() => import('./header/header.js'));
const Footer = lazy(() => import('./footer/footer.js'));
const BookView = lazy(() => import('./booking/bookView.js'));
const BookOptions = lazy(() => import('./bookingOptions/bookingOptions.js'));
const CurrentAndPastBookings = lazy(() => import('./booking/currentAndPastBookings.js'));
const ClientList = lazy(() => import('./therapist/clientList.js'));
const BookingList = lazy(() => import('./therapist/bookingList/bookingList.js'));
const Absences = lazy(() => import('./therapist/absences/absences.js'));
const DrawingBoard = lazy(() => import('./therapist/drawingBoard.js'));
const OperationPlan = lazy(() => import('./therapist/operationPlan/operationPlan.js'));
const About = lazy(() => import("./aboutUs/about.js"));
const Home = lazy(() => import('./home/home.js'));
const Vouchers = lazy(() => import('./vouchers/vouchers.js'));
const Pricing = lazy(() => import('./pricing/pricing.js'));
const TherapyList = lazy(() => import('./therapy/therapyList.js'));
const Impressum = lazy(() => import('./legal/impressum.js'));
const AGB = lazy(() => import("./legal/agb.js"));
const Journal = lazy(() => import('./therapist/journal.js'));
const CashRegister = lazy(() => import('./therapist/cashRegister/cashRegister.js'));
const VoucherManagement = lazy(() => import('./therapist/voucher/voucherManagement.js'));
const ProcessingCircle = lazy(() => import('./utils/processingCircle.js'));
const LoginLinkRequest = lazy(() => import('./login/loginLinkRequest.js'));

const resources = {
  de: { translation: deTranslation },
  en: { translation: enTranslation },
  ru: { translation: ruTranslation },
};

i18next.use(initReactI18next).init({
  resources,
  lng: getBrowserLanguage(resources),
  fallbackLng: 'de',
  interpolation: {
    escapeValue: false,
  },
});

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [fetchUserStatus, setFetchUserStatus] = useState(null);
  const [loginUser, setLoginUser] = useState(() => {
    const cookieValue = Cookies.get('user');
    return cookieValue ? JSON.parse(cookieValue) : null;
  });
  const searchParams = new URLSearchParams(location.search);
  const atBooking = useMatch('/book');
  const isHome = useMatch('/');
  const loginRequiredList = [
    useMatch('/bookings'), 
    useMatch('/account'), 
    useMatch('/clientList'), 
    useMatch('/bookingList'), 
    useMatch('/drawingBoard'), 
    useMatch('/operationPlan'), 
  ];
  const isLoginRequired = loginRequiredList.some(item => item !== null && item !== undefined);
  const hideHeaderAndFooter = useMatch('/drawingBoard');

  const language = searchParams.get('lang');
  useEffect(() => {
    if (language) {
      i18next.changeLanguage(language);
    }
  }, [language]);

  if (isHome && fetchUserStatus === "redirect") {
    setFetchUserStatus(null);
  }

  const { t, i18n } = useTranslation();
  const [activePopUp, setActivePopUp] = useState(useMatch('/book') && !loginUser ? "login" : null);

  const changeLanguage = (language) => {
    setPopup("");
    i18n.changeLanguage(language);
  };

  const setPopup = (popupType, e) => {
    // console.log("menu choice: " + (popupType || "none"));
    if (e)
      e.preventDefault();
    setActivePopUp(popupType);
  };

  useEffect(() => {
    if (IsProd()) {
      const script = document.createElement('script');
      script.async = true;
      script.src = 'https://www.googletagmanager.com/gtag/js?id=AW-16578861916';
      document.head.appendChild(script);
      script.onload = () => {
        const inlineScript = document.createElement('script');
        inlineScript.innerHTML = `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'AW-16578861916');`;
        document.head.appendChild(inlineScript);
      };
    }
  }, []);

  //loginUser (from useState) is frequently saved into cookies
  const reloadLoginUser = () => {
    const cookieValue = Cookies.get('user');
    if (cookieValue && cookieValue !== undefined && cookieValue !== "undefined") {
      const newUser = JSON.parse(cookieValue);
      setLoginUser(newUser);
    } else {
      setLoginUser(null);
    }
  };
  useEffect(() => {
    if (atBooking) {
      const intervalId = setInterval(() => {
        reloadLoginUser();
      }, 1000);
      reloadLoginUser();
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [atBooking]);

  const removeCredentialsFromUrl = () => {
    searchParams.delete('email');
    searchParams.delete('pw');
    const updatedUrl = `${location.pathname}?${searchParams.toString()}`;
    navigate(updatedUrl);
  };

  const logout = async () => {
    setActivePopUp("login");

    // Step 1: Redirect if restricted
    if (isLoginRequired) {
      navigate('/');
    }

    // Step 2: Notify server about logout
    try {
      const response = await axios.post('/api/account/logout.php');
      if (response.data.success) {
          console.log('User logged out successfully.');
      }
    } catch (error) {
        console.error('An error occurred during the logout process:', error);
    }

    // Step 3: Clear client-side cookies
    Cookies.remove('user');
    Cookies.remove('PHPSESSID');

    setLoginUser(null);
  };

  const setLoginUserCacheAndCookies = (user) => {
    if (user == null) {
      logout();
    } else {
      console.log("user set in cookies");
      Cookies.set('user', JSON.stringify(user), { sameSite: 'Strict' });
    }

    setFetchUserStatus("done");
    setLoginUser(user);
  };

  const updateLoginUser = (property, value) => {
    loginUser[property] = value;
    setLoginUserCacheAndCookies(loginUser);
  }
  
  // Ensure authentification where needed
  useEffect(() => {
    if (fetchUserStatus === null) {
      const email = searchParams.get('email');
      const pw = searchParams.get('pw');
      const loginDataFromUrl = email !== null && email !== undefined && pw !== null && pw !== undefined;
      if (isLoginRequired && !loginUser && !loginDataFromUrl) {
        // Without login and without authentication-info... => we only can redirect to home.
        setFetchUserStatus("unauthenticated");
        setPopup("login");
        return;
      }
  
      if (loginDataFromUrl) {
        // Change fetch-user-status to loading. Loading / login fallows right ahead...
        setFetchUserStatus("loading");
  
        // Login at server
        const postData = { 
          email, 
          pw: pw || ''
        };
        axios.post('/api/account/login.php', postData)
          .then(response => {
            if (response.data && response.data["statusLabel"] && response.data.statusLabel === "login-link-needed") {
                setFetchUserStatus("login-link-needed");
            } else {
                // Set login user
                // console.log("user: " + response.data.user);
                if (response.data.success) {
                    setFetchUserStatus(null);
                    setLoginUserCacheAndCookies(response.data.user);
                } else {
                    setFetchUserStatus("unauthenticated");
                    setPopup("login");
                }
                
                // Navigate to URL without authentification information
                removeCredentialsFromUrl();
            }
          })
          .catch(error => {
              console.error('Error:', error);
          });
      }
    }
  }, []);

  var authenticationView = null;
  if (fetchUserStatus === "redirect") {
      // This isn't really displayed as we redirect...
      // But it's randered anyway and therefore needed to supress to go into Routes (farther and more randering).
      authenticationView = (
        <h2>Redirect...</h2>
      );
  } else if (fetchUserStatus === "loading") {
    // Data loading / login in progress...
    authenticationView = (
      <div>
        <h2>{t('data-loading')}</h2>
        <h2>
          <ProcessingCircle></ProcessingCircle>
        </h2>
      </div>
    );
  } else if (fetchUserStatus === "login-link-needed") {
    // The login link is outdated. Therefore a new link is to be sent to the client.
    const email = searchParams.get('email');
    authenticationView = <LoginLinkRequest email={email} ></LoginLinkRequest>
  } else if (fetchUserStatus === "unauthenticated" || isLoginRequired && !loginUser) {
    // Login needed first
    authenticationView = (
      <h2>{t('data-unauthenticated')}</h2>
    );
  }

  return (
    <>
      {hideHeaderAndFooter ? null : 
        <Header loginUser={loginUser} activePopUp={activePopUp} setPopup={setPopup}></Header>
      }
      <ScrollToTop />
      <Anchor />
      {authenticationView ? authenticationView : (
        <Suspense fallback={<ProcessingCircle />}>
          <Routes>
            <Route path="/" element={<Home loginUser={loginUser} />} />
            <Route path="/vouchers" element={<Vouchers loginUser={loginUser} logout={logout} setPopup={setPopup} />} />
            <Route path="/calendar" element={<Calendar />} />
            <Route path="/about" element={<About />} />
            <Route path="/therapy" element={<TherapyAndDuration />} />
            <Route path="/therapies" element={<TherapyList />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/contacts" element={<ContactsAndLocation />} />
            <Route path="/impressum" element={<Impressum />} />
            <Route path="/agb" element={<AGB />} />
            <Route path="/refund" element={<Refund />} />
            <Route path="/bookoptions" element={
              <CalendarSelectionProvider>
                <BookOptions loginUser={loginUser} logout={logout} setLoginUserCacheAndCookies={setLoginUserCacheAndCookies} setFetchUserStatus={setFetchUserStatus} setPopup={setPopup} />
              </CalendarSelectionProvider>
            } />
            <Route path="/book" element={<BookView loginUser={loginUser} logout={logout} updateLoginUser={updateLoginUser} setPopup={setPopup} />} />
            <Route path="/bookings" element={<CurrentAndPastBookings loginUser={loginUser} logout={logout} />} />
            <Route path="/account" element={<Account loginUser={loginUser} logout={logout} updateLoginUser={updateLoginUser} minimal={false} />} />
            <Route path="/clientList" element={<ClientList loginUser={loginUser} />} />
            <Route path="/bookingList" element={<BookingList loginUser={loginUser} />} />
            <Route path="/absences" element={<Absences loginUser={loginUser} />} />
            <Route path="/drawingBoard" element={<DrawingBoard loginUser={loginUser} />} />
            <Route path="/operationPlan" element={<OperationPlan loginUser={loginUser} />} />
            <Route path="/journal" element={<Journal loginUser={loginUser} />} />
            <Route path="/cashRegister" element={<CashRegister loginUser={loginUser} logout={logout} />} />
            <Route path="/voucherManagement" element={<VoucherManagement loginUser={loginUser} logout={logout} />} />
          </Routes>
        </Suspense>
      )}
      {hideHeaderAndFooter ? null : 
        <Footer></Footer>
      }
      <div className="menu-extension">
        <LanguageMenu 
          activePopUp={activePopUp} 
          setActivePopUp={setActivePopUp} 
          changeLanguage={changeLanguage} />
        <LoginMenu 
          activePopUp={activePopUp} 
          setActivePopUp={setActivePopUp} 
          setPopup={setPopup} 
          logout={logout} 
          loginUser={loginUser}
          setLoginUserCacheAndCookies={setLoginUserCacheAndCookies}>
        </LoginMenu>
        <TherapistPopUp
          activePopUp={activePopUp} 
          setActivePopUp={setActivePopUp} 
          setPopup={setPopup} 
          logout={logout} >
        </TherapistPopUp>
      </div>
    </>
  );
}

export default App;
