import { useTranslation } from "react-i18next";
import EmailSvg from "../header/img/emailSvg.js";
import PhoneSvg from "../header/img/phoneSvg.js";
import WhatsappSvg from "../header/img/whatsappSvg.js";

//CSS
import "./contacts.css";

export default function Contacts() {
    const { t } = useTranslation();

    return (
        <p className="contacts">
            Albina Schiltknecht<br />
            {t('home-own-company-name')}<br />
            {t('location-street-no')}<br />
            {t('location-zip-city')}<br />
            <br />
            <a className="underline" href="mailto:info@anatomicus.ch">
            <EmailSvg></EmailSvg>
            info@anatomicus.ch
            </a>
            <br />
            <a className="underline" href="tel:0445937337">
            <PhoneSvg></PhoneSvg>
            044 593 73 37
            </a>
            <br />
            <a className="underline" href="https://wa.me/410766254555">
            <WhatsappSvg></WhatsappSvg>
            076 625 45 55
            </a>
        </p>
    );
}
