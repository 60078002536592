import { useTranslation } from "react-i18next";
import GoogleMaps from "./googleMap.js";

//SVGs
import BusSvg from "./img/transportAndParking/busSvg.js";
import TrainSvg from "./img/transportAndParking/trainSvg.js";
import TramSvg from "./img/transportAndParking/tramSvg.js";
import CarSvg from "./img/transportAndParking/carSvg.js";

//CSS
import "./location.css";

export default function Location() {
    const { t } = useTranslation();
    return (
        <div className="location-container">
        <div className="location">
          <div className="location-item">
            <div className="location_left">
              <div className="location_header_top">
                <h1>{t('home-visit-us')}</h1>
              </div>
              <div className="location_address_mid">
                <div className="address_left">
                  <li>{t('home-own-company-name')}</li>
                  <li>{t('location-street-no')}</li>
                  <li>{t('location-zip-city')}</li>
                </div>
                <div className="address_right">
                  <li>{t('openinghours-header')}</li>
                  <li>{t('openinghours-content1')}</li>
                  <li>{t('openinghours-content2')}</li>
                </div>
              </div>
              <div className="location_oev_bottom">
                <h2>{t('home-directions')}</h2>
                <ul>
                  <li>
                    <BusSvg></BusSvg>
                    <span>762</span>
                  </li>
                  <li>
                    <TramSvg></TramSvg>
                    <span>T12</span>
                  </li>
                  <li>
                    <BusSvg></BusSvg>
                    <span>S16</span>
                  </li>
                  <li>
                    <BusSvg></BusSvg>
                    <span>761</span>
                  </li>
                  <li>
                  <TramSvg></TramSvg>
                    <span>T10</span>
                  </li>
                  <li>
                    <TrainSvg></TrainSvg>
                    <span>S15</span>
                  </li>
                  <li>
                    <TrainSvg></TrainSvg>
                    <span>S7</span>
                  </li>
                  <li>
                    <CarSvg></CarSvg>
                    <span>Park</span>
                  </li>
                  <li>
                    <CarSvg></CarSvg>
                    <span>Etc.</span>
                  </li>
                </ul>
              </div>
              <p>
                {t('home-parking-info1')}
                <br></br>
                {t('home-parking-info2')}
              </p>
            </div>
            <div className="location_right">
              <div className="maps">
                <GoogleMaps />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}
