import React from 'react';

export default function TrainSvg({ className, width, height }) {
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg"
            className={className} 
            width={width} 
            height={height} 
            viewBox="0 0 256 256"
            strokeWidth=""
            stroke="currentColor">
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M237 87c-.881-7.552-2.356-16.017-15-16h-69l35-26-60-34-60 34 35 26h-103v13h123c3.206.475 5.002 2.284 5 6v38c.014 3.839-1.794 5.673-5 6h-123v53h251l-14-100zm-120-16l-33-25 44-25 44 25-33 25h-22zm42 175c13.807 0 25-11.193 25-25s-11.193-25-25-25-25 11.193-25 25 11.193 25 25 25zm-62 0c13.807 0 25-11.193 25-25s-11.193-25-25-25-25 11.193-25 25 11.193 25 25 25zm156-38h-57v36h60v-12l-3-24zm-253 0h60v36h-60v-36zm226-74h-33c-2.597 0-4-1.339-4-3.922v-42.157c0-2.583 1.403-3.922 4-3.922h27c1.478 0 3.023 1.201 4 3.922l6 41.176c.013 3.313-1.39 4.902-4 4.902z" />
        </svg>
   );
}
